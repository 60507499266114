import styled from "styled-components";

export const StarUl = styled.ul`
  list-style-type: none;
  font-size: 20px;
  font-family: var(--uiFont);
  max-width: 480px;
  width: 100%;
  margin: ${(props) =>
    props.spaceAbove
      ? "var(--insidePadding) auto 0"
      : "0 auto var(--outsidePadding)"};
  padding: 0;
  & li {
    line-height: 32px !important;
    margin: 0;
    padding: 0;
    text-align: center;
    & a {
      &:hover {
        font-weight: bold;
        text-decoration: none;
      }
    }
    &:before {
      content: "★ ";
      color: var(--gold);
    }
    &:after {
      content: " ★";
      color: var(--gold);
    }
    & + li {
      margin-top: var(--insidePadding);
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
  @media screen and (max-width: 350px) {
    font-size: 16px;
  }
`;

export const OptionsDiv = styled.div`
  font-family: var(--uiFont);
  background-color: var(--blue);
  color: var(--white);
  display: flex;
  margin: var(--insidePadding) auto var(--outsidePadding) auto;
  padding: var(--insidePadding) var(--outsidePadding);
  font-size: 14px;
  border-radius: var(--outsidePadding);
  align-items: baseline;
  user-select: none;
  box-shadow: 0 0 10px rgba(51, 52, 141, 0.5);
  & ul {
    margin: 0;
    padding: 0;
    display: inline-flex;
    & li {
      margin: 0 0 0 1em;
      padding: 0;
      display: inline-block;
      & a {
        color: var(--gold);
        font-weight: bold;
        transition: 0.5s;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        &:hover {
          color: var(--white);
          text-decoration: none;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    & span {
      text-align: center;
    }
  }
`;

export const FreeSampleDiv = styled.div`
  font-family: var(--textFont);
  display: flex;
  flex-direction: column;
  max-width: calc(600px + 40px);
  padding: var(--outsidePadding);
  /* border: 1px solid #ccc; */
  box-sizing: border-box;
  & .ul {
    text-decoration: underline;
  }
  & .sc {
    font-variant-caps: small-caps;
    -moz-font-feature-settings: "smcp";
    -webkit-font-feature-settings: "smcp";
    font-feature-settings: "smcp";
    text-transform: lowercase;
    letter-spacing: 2px;
  }
  & .leftalign {
    & blockquote {
      text-align: left;
      & + blockquote {
        margin-top: -1em;
      }
    }
  }
  & h1 {
    font-size: 36px;
    line-height: 36px;
    text-align: center;
    color: var(--red);
  }
  & > div > p:first-child {
    &:first-line {
      font-variant-caps: small-caps;
      -moz-font-feature-settings: "smcp";
      -webkit-font-feature-settings: "smcp";
      font-feature-settings: "smcp";
      letter-spacing: 2px;
    }
    &:first-letter {
      float: left;
      font-size: 600%;
      line-height: 0.85;
      padding-top: 0;
      margin: 0;
      padding-right: 5px;
    }
  }
  & p {
    margin: 0;
    line-height: 1.75;

    & .sc {
      letter-spacing: 1px;
      text-transform: lowercase;
      -moz-font-feature-settings: "smcp";
      -webkit-font-feature-settings: "smcp";
      font-feature-settings: "smcp";
      letter-spacing: 2px;
    }
    & + p {
      text-indent: 2em;
    }
  }
  & hr {
    width: 50%;
    margin: 32px 25%;
  }
  & blockquote {
    border-left: 0;
    text-align: center;
    font-style: normal;
    margin: 32px;
    padding: 0;
    & p {
      margin: 0;
    }
    & + blockquote {
      margin-top: -32px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

export const BreadCrumb = styled.p`
  font-family: var(--uiFont);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: var(--outsidePadding) !important;
  user-select: none;
  & a {
    color: var(--red);
    &:hover {
      color: var(--blue);
      text-decoration: none;
    }
  }
`;
