import React from "react";
import styled, { keyframes } from "styled-components";
import Config from "./../../config";
// import BookCover from "./../images/ringondeli.png";

const buttonWobble = keyframes`
  0 {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

// maybe make this red and white striped?

const StoreButton = styled.button`
  background-color: var(--red);
  color: var(--white);
  font-family: var(--uiFont);
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  user-select: none;
  font-size: 20px;
  margin: 0;
  padding: 4px 12px;
  border-radius: 16px;
  &:hover,
  &:focus {
    outline: none;
    background-color: var(--blue);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    animation: ${buttonWobble} ease-in-out 0.5s infinite;
  }
`;

const imageUrl = "images/ringondeli.png";

const BuyButton = ({ item }) => {
  const itemInfo = {
    id: item ? item.id : "ringondeli-specialedition",
    price: item ? item.price : 18, //20,
    name: item ? item.name : "Ring On Deli Special Edition",
    description: item
      ? item.description
      : "The special edition of <em>Ring On Deli</em>",
    image: item ? item.image : imageUrl,
    url: item ? item.url : "/about",
    buttonText: Config.preRelease
      ? "Coming soon!"
      : item
      ? item.buttonText
      : "Buy now!",
  };
  return (
    <StoreButton
      className={Config.preRelease ? "" : "snipcart-add-item"}
      data-item-id={itemInfo.id}
      data-item-price={itemInfo.price}
      data-item-name={itemInfo.name}
      data-item-description={itemInfo.description}
      data-item-image={itemInfo.image}
      data-item-url={`${Config.devUrl}${itemInfo.url}`}
    >
      {itemInfo.buttonText}
    </StoreButton>
  );
};

export default BuyButton;
