import React from "react";
import { Link } from "gatsby";
import {
  PageText,
  PageH1,
  PageWrapperDiv,
} from "./../styledcomponents/layoutstyles";
import {
  StarHR,
  PatchDiv,
  StoreTable,
  TDCenter,
} from "./../styledcomponents/storestyles";
// import Config from "./../../config";
import BuyButton from "./../components/buybutton";
import Patch1 from "./../images/patches1.jpg";
import Patch2 from "./../images/patches2.jpg";

// maybe make this into a table?

const StoreComponent = () => {
  return (
    <PageWrapperDiv>
      <PageH1>Buy!</PageH1>
      <PageText>
        <p className="center">
          Attention all shoppers! <em>Ring On Deli</em> is now available for
          purchase—
          <br />
          in paper, electronic, and audio formats!
        </p>
        <StoreTable>
          <tbody>
            <tr>
              <td>
                <strong>Special edition</strong>
              </td>
              <td>
                Direct from New Salem Books, the special deluxe-paperback
                edition comes signed and numbered by Eric Giroux himself!{" "}
                <strong>
                  <span style={{ textDecoration: "line-through" }}>$20</span>.
                  $18.
                </strong>{" "}
                Official merit-badge-style <em>Ring On Deli</em> patch included!
              </td>
              <TDCenter>
                <BuyButton />
              </TDCenter>
            </tr>
            <tr>
              <td>
                <strong>Regular edition</strong>
              </td>
              <td>
                The regular edition is not as fancy as the special edition, but
                it still contains all the words.
              </td>
              <TDCenter>
                Buy&nbsp;from&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bookshop.org/books/ring-on-deli/9781734224009"
                >
                  Bookshop
                </a>{" "}
                or&nbsp;your&nbsp;local&nbsp;bookstore!
              </TDCenter>
            </tr>
            <tr>
              <td>
                <strong>E-book</strong>
              </td>
              <td>
                <span>Your choice of format!</span>
              </td>
              <TDCenter>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.amazon.com/Ring-Deli-Eric-Giroux-ebook/dp/B08B3VXK97/"
                >
                  Kindle
                </a>
                ,{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.kobo.com/us/en/ebook/ring-on-deli"
                >
                  Kobo
                </a>
                , or{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.barnesandnoble.com/w/ring-on-deli-eric-giroux/1137162845?ean=9781734224023"
                >
                  Nook
                </a>
              </TDCenter>
            </tr>
            <tr>
              <td>
                <strong>Audio book</strong>
              </td>
              <td>
                <span>Download or unlimited streaming.</span>
              </td>
              <TDCenter>
                <a
                  href="https://newsalembooks.bandcamp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bandcamp
                </a>
              </TDCenter>
            </tr>
          </tbody>
        </StoreTable>
        <StarHR />
        <div>
          <p>
            For each copy of the special edition sold, the author will donate $1
            to the{" "}
            <a
              href="https://billericacommunitypantry.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Billerica Community Pantry
            </a>
            , which provides food at no cost to Billerica citizens in need.
          </p>
          <p>
            If you wish to buy the book in bulk or in foreign countries other
            than Canada, send us a note using the{" "}
            <Link to={`/contact`}>Contact Form</Link>.
          </p>
        </div>
        <StarHR />
        <p>
          If you are not content buying a print or audio edition of this book,
          you can also buy your very own <em>Ring On Deli</em> patch! They look
          like this:
        </p>
        <PatchDiv>
          <img src={Patch1} alt="Several Ring On Deli patches" />
          <img src={Patch2} alt="A single Ring On Deli patch" />
        </PatchDiv>
        <p>
          Sew them on whatever you have that needs a <em>Ring On Deli</em> patch
          on it, or get someone to do that for you. They’re fully embroidered
          and about 2'' in diameter and they cost $2 each. You’d get one for
          free if you bought the special edition of the book, but if that’s too
          fancy for you, you can buy them separately.
        </p>
        <p style={{ textAlign: "center", marginTop: 26 }}>
          <BuyButton
            item={{
              id: "ringondeli-badge",
              price: 2,
              name: "Ring On Deli Patch",
              description: "A patch that says <em>Ring On Deli</em>",
              image: Patch2,
              url: "/buy",
              buttonText: "Buy the Patch!",
            }}
          />
        </p>
      </PageText>
    </PageWrapperDiv>
  );
};

export default StoreComponent;
