import React from "react";
import { Link } from "gatsby";
import {
  OptionsDiv,
  FreeSampleDiv,
  BreadCrumb,
} from "./../styledcomponents/samplestyles";

const FreeSample = ({ html, slug, title, hasAudio }) => {
  return (
    <FreeSampleDiv>
      <BreadCrumb>
        <Link to={`/freesamples`}>« Free Samples</Link>
      </BreadCrumb>
      <h1>{title}</h1>
      <OptionsDiv>
        <span className="italic">
          You can read or listen to this sample in another way:
        </span>
        <ul>
          <li>
            <a href={`/pdfs/${slug}.pdf`} download>
              PDF
            </a>
          </li>
          {hasAudio ? (
            <li>
              <a href={`/audio/${slug}.mp3`} download>
                Audio version
              </a>
            </li>
          ) : null}
        </ul>
      </OptionsDiv>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </FreeSampleDiv>
  );
};

export default FreeSample;
