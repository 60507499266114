import styled from "styled-components";
import stars from "./../images/starswhite.svg";

export const StoreTable = styled.table`
  margin-top: 6px;
  font-size: 18px;
  line-height: 26px;
  & tr {
    background-color: transparent;
    & + tr {
      border-top: 1px solid rgba(45, 58, 141, 0.5);
    }
    &:nth-child(2n) {
      background-color: transparent;
    }
    & td {
      padding: 10px 0px;
      vertical-align: top;
      & + td {
        padding-left: 16px;
        & + td + td {
          text-align: center;
        }
      }
      & strong {
        white-space: nowrap;
      }
      & button {
        white-space: nowrap;
      }
      & a {
        font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    & tr {
      display: flex;
      flex-direction: column;
      & td {
        padding: 0;
        padding-top: 10px;
        text-align: center;
        & + td {
          padding-top: 0;
          padding-left: 0;
          padding-bottom: 10px;
        }
      }
    }
  }
`;

export const PatchDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 26px 0;
  & img {
    width: calc(50% - 13px - 8px - 8px);
    height: auto;
    max-height: 460px;
    border: 8px solid var(--blue);
    border-radius: 8px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    & img {
      box-sizing: border-box;
      width: 100%;
      height: auto;
      margin-bottom: var(--outsidePadding);
    }
  }
`;

export const TDCenter = styled.td`
  text-align: center;
`;

export const StarHR = styled.p`
  text-align: center;
  margin: 13px 0 !important;
  color: var(--gold);
  font-size: 20px !important;
  &:before {
    content: "★ ★ ★ ★ ★";
    letter-spacing: 10px;
  }
`;

export const MethodContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 26px 0;
  padding: var(--outsidePadding);
  border-radius: var(--insidePadding);
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: var(--insidePadding);
    background-color: var(--blue);
    background-image: url(${stars});
    background-size: 20%;
  }
`;

export const MethodBoxDiv = styled.div`
  box-sizing: border-box;
  position: relative;
  background-color: var(--white);
  border-radius: var(--insidePadding);
  padding: var(--outsidePadding);
  max-width: 349px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  & h2 {
    margin-top: 0;
    line-height: 1.25;
    margin-bottom: 7.5px;
    & em {
      border-top-left-radius: var(--insidePadding);
      border-top-right-radius: var(--insidePadding);
      text-transform: uppercase;
      font-style: normal;
      letter-spacing: 2px;
      font-size: 75%;
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      color: white;
      background-color: var(--red);
      background-image: url(${stars});
      background-size: 40%;
      padding: 10px 20px;
      justify-content: center;
    }
  }
  & button {
    margin: 21px auto 0;
  }
`;
