import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import FreeSample from "../components/freesample";
import StoreComponent from "../components/storecomponent";
import BuyButton from "../components/buybutton";
import {
  PageText,
  PageH1,
  PageWrapperDiv,
} from "./../styledcomponents/layoutstyles";
import { StarUl } from "./../styledcomponents/samplestyles";
import BlurbSlider from "./../components/blurbslider";

export default function PageTemplate({ data, pageContext }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout
      bookData={frontmatter.slug === "about"}
      title={`Ring On Deli: ${frontmatter.title}` || ""}
    >
      <PageWrapperDiv>
        {pageContext.type === "sample" ? (
          <FreeSample
            html={html}
            slug={frontmatter.slug}
            title={frontmatter.title}
            hasAudio={frontmatter.hasaudio}
          />
        ) : frontmatter.slug === "buy" ? (
          <StoreComponent />
        ) : (
          <>
            <PageH1>{frontmatter.title}</PageH1>
            <PageText dangerouslySetInnerHTML={{ __html: html }} />
            {frontmatter.slug === "about" ? (
              <React.Fragment>
                <StarUl spaceAbove>
                  <li>
                    <Link to={`/freesamples/`}>Read some of the book!</Link>
                  </li>
                  <li>
                    <BuyButton />
                  </li>
                </StarUl>
                <BlurbSlider />
              </React.Fragment>
            ) : null}
          </>
        )}
      </PageWrapperDiv>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        hasaudio
      }
    }
  }
`;
