const Config = {
  prodUrl: "https://ringondeli.com",
  devUrl: "https://ringondeli.netlify.app",
  snipcart:
    "MTRlZGUzZmQtMzAxYi00YjUwLTlkZTAtZGQyZTk3NjhkNjM2NjM3MjUwNzc3OTQ3ODg5MTM2",
  preRelease: false,
  isPreOrder: true, // this changes 10 August 2020!
  netlifyForms: true,
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || "ynvugy98",
    dataset: process.env.GATSBY_SANITY_DATASET || "production",
  },
  sanityAPI:
    "skQPTLASFsKPbAFjmHDqWJSz1UBDOPM2YE0DFPdshR8pg8ZTV1dJmCCY7OUBwTkDjK9pEqZRPDgAX6Iay4OLlEDHGUreFTgoM2ZdhdnUydwMoTVAf3UKZ9Obkyfv0RJ0UumN2YgSyxTJPTKsnkyHGToHs724QJ422e4ugleGZRbnRL1Qicjw",
};

export default Config;
